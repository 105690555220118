import { useLocation, Navigate } from "react-router-dom";
import Login from "../Component/Authentication/Login";
import Default from "../Component/Dashboard/Default/Default";
import FormWizardOneContainer from "../Component/Forms/FormsLayout/FormWizardOne/FormWizardOne";

const CustomRouter = () => {
  const location = useLocation();
  const login = localStorage.getItem("login"); // Check authentication status
  const { pathname, search } = location; // Capture the intended path

  // Define route mapping logic
  if (!login) {
    localStorage.setItem("redirectAfterLogin", pathname + search);
    console.log("Redirecting to login page");
    console.log(pathname, search);
    return <Login />;
  }

  // Authenticated route logic
  switch (pathname) {
    case "/dashboard/default":
      return <Default />;
    case "/utilization-report":
      return <Default />;
    case "/screening":
      return <FormWizardOneContainer />;
    case "/screening/form":
      return <FormWizardOneContainer />;
    default:
      // Redirect to default dashboard if route is not matched
      return <Navigate to='/dashboard/default' replace />;
  }
};

export default CustomRouter;
