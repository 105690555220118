import { Container, Row, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, Default } from "../../../utils/Constant";
import { useNavigate } from "react-router-dom";
import ProfileGreet from "./ProfileGreet/ProfileGreet";
import Notifications from "./Notifications/Notifications";
import RevenueForcast from "./RevenueForecast/RevenueForecast";
import FormWizardOneContainer from "../../Forms/FormsLayout/FormWizardOne/FormWizardOne";
import FormWizardOne from "../../Forms/FormsLayout/FormWizardOne/FormWizardOne";
import UtilizationReport from "./HDMReports/UtilizationReports";

const ContainerDefault = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid className='default-dashboard'>
        <Row>
          {/* <FormWizardOne /> */}
          {/* <ProfileGreet /> */}
          <Breadcrumbs mainTitle={Default} parent={Dashboard} />
          <UtilizationReport />
          {/* <RevenueForcast /> */}
          {/* <ProjectOverview />
          <Investing /> */}
          {/* <TotalClients />
          <AllInvoices />
          <TotalInvestment />
          <TaskList />
          <MonthlyOverview />
          <TaskSummary />
          <CoursesHighlighted />
          <ScheduleTime />
          <TotalVisit />
          <Notifications /> */}
        </Row>
      </Container>
    </>
  );
};

export default ContainerDefault;
